<div class="shadow rounded-lg">

    <div class="flex flex-row items-center p-4 cursor-pointer" (click)="toggle()" (keydown)="toggle()">
        <div class="flex justify-center items-center w-12 h-12 rounded-full bg-slate-800">
            <div class="text-white text-lg font-bold">{{userInitials | uppercase}}</div>
        </div>
        <div class="flex flex-col pl-2 max-w-44">
            <span class="text-base font-semibold overflow-hidden text-ellipsis text-nowrap text-stactize-dark-blue">
                {{userName}}
            </span>
            @if (alwaysExpanded || isExpanded) {
            <span class="text-base font-normal text-secondary">{{userRole}}</span>
            }
            @else {
            <div class="flex flex-row justify-center text-base font-normal text-actionable-primary">
                <span>Account settings</span>
                <mat-icon fontIcon="keyboard_arrow_down"></mat-icon>
            </div>
            }
        </div>
    </div>
    <hr />
    <div
        class="flex flex-col transition-all duration-300 overflow-hidden {{alwaysExpanded || isExpanded ? 'max-h-96' : 'max-h-0'}}">
        <div class="p-4">
            @if (userProfileFeatureEnabled()) {
            <a class="flex flex-row h-10 items-center text-actionable-primary text-md font-semibold"
                routerLink="/profile">
                <mat-icon class="flex-none" fontIcon="contact_page"></mat-icon>
                <span class="pl-4" aria-label="Link to Profile">Profile</span>
            </a>
            }
            @if (userManagementFeatureEnabled()) {
            <a class="flex flex-row h-16 items-center text-actionable-primary text-md font-semibold"
                routerLink="/users">
                <mat-icon class="flex-none" fontIcon="people"></mat-icon>
                <span class="pl-4" aria-label="Link to User management">User management</span>
            </a>
            }
            @if (externalConnectionsFeatureEnabled()) {
            <a class="flex flex-row h-10 items-center text-actionable-primary text-md font-semibold"
                routerLink="/external-marketplace-connections">
                <mat-icon class="flex-none" fontIcon="electrical_services"></mat-icon>
                <span class="pl-4 " aria-label="Link to External marketplace connections">External marketplace
                    connections</span>
            </a>
            }
            <button class="flex flex-row h-10 items-center text-actionable-primary text-md font-semibold"
                (click)="logOut()">
                <mat-icon class="flex-none" fontIcon="lock_open"></mat-icon>
                <span class="pl-4" aria-label="Link to Log out">Log out</span>
            </button>
        </div>
    </div>
</div>